import styled from 'styled-components'
import { color, typography, Media } from '@renderbus/common/theme'
import { H1, H2, Paragraph } from '@renderbus/common/components'

export const PartnerBanner = styled.div`
  position: relative;
  width: 100%;
  height: 21.875vmax;
  margin-top: 60px;

  ${Media.lessThan(Media.medium)} {
    height: 100vmin;
  }
`
export const TextContent = styled.div`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;

  ${Media.lessThan(Media.medium)} {
    padding: 0 6.66666vmin;
    left: initial;
    transform: translateY(-50%);

    & > ${H1} {
      font-size: 6.4vmin;
    }

    & > ${Paragraph} {
      font-size: 3.2vmin;
      line-height: 4.8vmin;
      color: ${color.white};
      margin: 3.2vmin 0 0;
    }
  }
`
export const Section = styled.section`
  background: rgb(40, 40, 40);
  padding: 90px 0;
  color: ${color.white};

  & > .section-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 1400px;
    margin: 0 auto;

    & > .partner-detail-title {
      align-self: flex-start;
      border-left: 5px solid ${color.primary};
      padding-left: 18px;
    }
  }

  ${Media.lessThan(Media.medium)} {
    padding: 8vmin 0 10.66666667vmin;

    & > .section-content {
      margin: 0 4vmin;

      & > ${H2} {
        font-size: 5.33333vmin;
      }
    }
  }
`

export const PartnerLogoList = styled.div`
  display: flex;
  gap: 20px 16px;
  flex-flow: row wrap;
  margin-top: 60px;
  margin-bottom: 80px;

  > .partner-item {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 104px;
    background: white;
    overflow: hidden;

    & > img {
      max-width: 100%;
    }
  }

  ${Media.lessThan(Media.medium)} {
    gap: 2vmin;
    margin: 8vmin 0 10.66666667vmin;

    & > .partner-item {
      width: 29.33333333333333vmin;
      height: 13.86666666666667vmin;
      padding: 0 2.66666666666667vmin;
    }
  }
`

export const PartnerDetails = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px 25px;
  margin-top: 60px;

  & > .partner-detail-item {
    width: 450px;
    padding: 24px 32px;
    background: #383838;
    border: 1px solid #191919;

    & > .title {
      font-size: ${typography.h4};
      margin: 0 0 20px;
    }

    & > .content {
      font-size: ${typography.textSmall};
      margin: 0;
    }
  }

  ${Media.lessThan(Media.medium)} {
    flex-flow: column nowrap;
    gap: 3.2vmin;
    margin-top: 10.66666667vmin;

    & > .partner-detail-item {
      width: 100%;
      padding: 4.66666666666667vmin 6vmin 6vmin;

      & > .title {
        font-size: 4vmin;
        margin: 0 0 4vmin;
      }

      & > .content {
        font-size: 1.6vmin;
      }
    }
  }
`
